.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #BECAFF !important;
  border-color: #BECAFF !important;
}

.anticon svg {
  fill: #BECAFF !important;
}

.ant-upload.ant-upload-drag:hover {
  border: 1px dashed #BECAFF !important;
}
