/* RECOVER COMPONENT CSSS */
.recoverOverallContainer {
    display: flex;
    flex-direction: column;
    margin: 20px;
    width: 100%;
    height: 100%;
  }

.backArrow{
    width: 30px;
    height: 30px;
    border: 1px solid #e1e8e8;
    border-radius: 12px !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.backArrowIcon{
    margin: 10px;
}

.innerBody{
    width:80%;
}

.description{
    margin-top: 20%;
    font-style: normal;
    font-size: 28px;
    line-height: 33.6px;
    font-weight: 400;
    font-family: 'TT Norms Pro';
}

.button{
    margin-top: 20px;
    width:100%;
}

.recoverLink {
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #999cad;
    margin-top: 20px;
  }

.recoverLink a {
    color: #999cad !important;
    text-decoration: underline;
}
