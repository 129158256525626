.heading {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 12px;
}

.instruction {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
  margin-bottom: 49px;
}

.drag {
  margin-bottom: 29px;
}

.invoiceName {
  height: 56px;
}

.uploadBtn {
  margin-top: 30px;
  width: 100%;
}
