.invoiceUploadContainer {
  padding: 33px 40px;
}

.backArrowIcon{
  margin: 10px;
}

.backArrow{
  width: 30px;
  height: 30px;
  border: 1px solid #e1e8e8;
  border-radius: 12px !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}