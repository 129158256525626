.holding_space {
  width: 100%;
  justify-content: center;
  margin-top: 88px;
}

.content_div {
  text-align: center;
  width: 80%;
}

.first_heading {
  font-weight: 700;
  font-size: 28px;
  color: #333333;
  margin-bottom: 0px;
}

.second_heading {
  font-weight: 400;
  font-size: 28px;
  color: #333333;
  margin-bottom: 0.5em;
}

.user_name {
  color: #333333;
  top: 1px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.01em;
}
