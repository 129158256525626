.wrapper {
  display: flex;
  flex-flow: column;
  font-family: 'TT Norms Pro';
  justify-content: flex-start;
  align-items: center;
  position: relative;
  max-width: 100%;
  height: 100%;
}

.label {
  color: #999cad;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 14px;
}

.container {
  width: 100%;
  height: 100%;
  padding-inline: 3rem !important;
  padding-block: 2rem !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.innerwrap {
  padding-top: 20%;
}

.subcaption,
.caption {
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 24px;
}

.caption h1 {
  font-family: 'TT Norms Pro';
}

.subcaption {
  width: 13ch;
  padding-top: 8px;
}

.inputBox {
  padding-top: 26px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}

.formbtn {
  padding-bottom: 1rem;
}

.formbtn .submitBtn,
.formbtn .ant-btn {
  box-shadow: 0px 15px 15px rgba(107, 107, 107, 0.25) !important;
  border-radius: 10px !important;
  height: 50px !important;
  min-width: unset;
  width: 100%;
}

.recoverLink {
  margin-top: 6px;
  margin-left: 0;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

.recoverLink a,
.signupLink a {
  color: #999cad;
  text-decoration: underline;
}

.signupLink {
  text-align: center;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #999cad;
  position: absolute;
  bottom: 0;
}

.mainWrapper {
  display: flex;
  justify-content: center;
  background: #E5EAFF;
  font-family: 'TT Norms Pro';
  position: relative;
}

.radialWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.authTitle {
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  font-family: 'TT Norms Pro bold';
  font-weight: 700;
}

.authSubTitle {
  font-size: 28px;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
}

.stepperWrapper {
  margin-top: 106px;
  align-items: left;
  display: flex;
  justify-content: space-between;
}

.stepperItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.stepperItem::before {
  position: absolute;
  content: "";
  border-bottom: 1.5px solid #BECAFF;
  width: 100%;
  top: 10px;
  left: -50%;
  z-index: 2;
}

.stepperItem .stepCounter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #BECBFD;
  margin-bottom: 6px;
}

.stepperItem:first-child::before {
  content: none;
}

.stepperItem:last-child::after {
  content: none;
}

.stepperIcon {
  margin-top: 9.05px;
  float: left;
}

.theiaLogo {
  width: 114px;
  height: 100px;
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.stepperDescription {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.17px;
  width: 123px;
  margin-left: 100px;
  font-family: 'TT Norms Pro bold';
}

@media only screen and (max-width: 786px) {
  .container {
    justify-content: center;
    padding-block: 0 !important;
    min-height: 0;
  }
}

@media only screen and (max-width: 320px) {

  .subcaption,
  .caption {
    font-size: 8vw;
  }

  .caption {
    font-size: 17vw;
    line-height: 33.3px;
  }

  .wrapper {
    height: 100%;
  }
}
