.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  transition: transform 0.3s;
  transform: rotate(90deg);
  cursor: pointer;
}
.ant-collapse
  > .ant-collapse-item-active
  > .ant-collapse-header
  .ant-collapse-arrow {
  transition: transform 0.3s;
  transform: rotate(180deg);
  cursor: pointer;
}
.ant-skeleton {
  margin-top: 20px;
}
