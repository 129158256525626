.valid {
  background-color: #6feeb1;
}
.invalid {
  background-color: #d8002799;
}
.text {
    font-size: 12px;
    color: #999cad;
  }
.badText {
  color: #d8002799;
}

.circle {
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-right: 6px;
  border-radius: 100%;
}
