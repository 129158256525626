.defaultFormInput {
  position: relative;
}

.ant-input {
  font-size: 16px;
  font-family: 'TT Norms Pro' !important;
}

.defaultFormInput-password.ant-input-password,
.defaultFormInput-input.ant-input,
.defaultFormInput .defaultFormInput-input {
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 4px;
  height: 58px;
  font-size: 16px !important;
}

.ant-input-affix-wrapper.defaultFormInput-input {
  width: 100%;
}

.ant-input::-webkit-input-placeholder,
.anticon-info-circle,
.ant-input-clear-icon {
  color: #adadad !important;
}

.defaultFormInput-label {
  position: absolute;
  color: #adadad !important;
  font-family: 'TT Norms Pro' !important;
  font-size: 16px !important;
  top: -10px;
  left: 20px;
  z-index: 100;
  background: white;
  padding: 0 10px 0 10px;
  visibility: hidden;
}

.defaultFormInput:focus-within .defaultFormInput-label,
.label-visible {
  visibility: visible;
}

.defaultFormInput:focus-within .ant-input::-webkit-input-placeholder {
  visibility: hidden;
}

div.defaultFormInput>span.defaultFormInput-label--error,
.ant-form-item-explain-error>div {
  color: #F37D80 !important;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.defaultFormInput:not(.defaultFormInput--error) .defaultFormInput-password.ant-input-affix-wrapper:focus,
.defaultFormInput:not(.defaultFormInput--error) .defaultFormInput-password.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.defaultFormInput:not(.defaultFormInput--error) .defaultFormInput-password.ant-input-affix-wrapper-focused,
.defaultFormInput:not(.defaultFormInput--error) .ant-input:focus,
.defaultFormInput:not(.defaultFormInput--error) .ant-input:hover,
.defaultFormInput:not(.defaultFormInput--error) .ant-input-focused,
.defaultFormInput:not(.defaultFormInput--error) .defaultFormInput-input.ant-input-affix-wrapper:focus,
.defaultFormInput:not(.defaultFormInput--error) .defaultFormInput-input.ant-input-affix-wrapper:hover,
.defaultFormInput:not(.defaultFormInput--error) .defaultFormInput-input.ant-input-affix-wrapper-focused {
  border-color: #ADADAD !important;
  box-shadow: unset !important;
  color: #333333 !important;
}

.defaultFormInput--error>.defaultFormInput-input,
.defaultFormInput--error>.defaultFormInput-input:focus,
.defaultFormInput--error>.defaultFormInput-input:hover,
.defaultFormInput--error>.defaultFormInput-input,
.defaultFormInput--error>.defaultFormInput-password:focus,
.defaultFormInput--error>.defaultFormInput-password:hover {
  border-color: #F37D80 !important;
  caret-color: #F37D80 !important;
  box-shadow: unset !important;
  color: #333333 !important;
}

.ant-form-item-explain-error>div::before {
  display: block;
  float: left;
  content: "!";
  border-radius: 50%;
  background-color: #F37D80;
  color: black;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  font-weight: bolder;
  margin: 3px;
}

.rc-virtual-list-holder-inner>.ant-select-item-option {
  padding: 5px 30px;
}

.ant-form-item-control .ant-form-item-explain {
  min-height: auto;
}

.defaultFormInput-info-label {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
}

/* change clearIcon icon to close in Input */
.anticon.anticon-close-circle.ant-input-clear-icon svg path {
  d: path('M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512') !important;
}

.ant-input-suffix {
  display: flex;
  flex-direction: row;
}

.anticon.anticon-close-circle.ant-input-clear-icon:not(.ant-input-clear-icon-hidden) {
  order: 2;
  padding-left: 10px;
}

.defaultFormInput .defaultFormInput-label:not(.label-visible)+.defaultFormInput-input .ant-input-suffix,
.defaultFormInput .defaultFormInput-label:not(.label-visible)+.defaultFormInput-password .ant-input-suffix {
  visibility: hidden;
}

.defaultFormInput:focus-within .ant-input-suffix,
.defaultFormInput .ant-input-affix-wrapper-disabled .ant-input-suffix
{
  visibility: visible !important;
}
