.wrapper{
    position: fixed;
    top: 3%;
    right: 3%;
}

.button {
    height: 75px;
    width: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: white;
    color: #000;
    border-radius: 50% !important;
}

.button:hover {background-color: #ebebeb}

.button:active {
    background-color: #BECAFF;
    transform: translateY(4px);
}