div.notify {
    width: 340px;
    min-height: 120px;
    color: #333;
    padding: 20px;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
}

div.success {
    background-color: #E9F6C5;
}
div.info {
    background-color: #E5EAFF;
}
div.warning {
    background-color: #FFEDBD;
}
div.error {
    background-color: #FCD8D6;
}

div.notify div.ant-notification-notice-description {
    font-family: 'TT Norms Pro';
    font-size: 0.875rem;
    line-height: 21px;
    margin-top: 13.5px;
    color: #333;
}

div.notify div.ant-notification-notice-message {
    font-family: 'TT Norms Pro Bold';
    font-size: 1rem;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #333;
    padding-top: 5.5px;
}

div.notify a.ant-notification-notice-close {
    top: 20px;
    right: 20px;
    height: 20px;
}
