.btn {
    min-height: 40px;
    padding: 0px 18px !important;
    border-radius: 4px !important;
    font-weight: 700 !important;
    font-family: 'TT Norms Pro';
    font-size: 14px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-style: normal;
    letter-spacing: 0.2px;
}

.logoContainer {
    margin-left: 8px;
    width: 20px;
    height: 20px;
}

.btn .primaryIconContainer {
    color: #ffffff;
    margin-right: 8px;
}

.btn .secondaryIconContainer {
    color: #333333;
    margin-right: 8px;
}

.btn .secondaryDisabledIconContainer {
    margin-right: 8px;
    color: #adadad;
}

.primarDisabled {
    background-color: #adadad !important;
    border: none !important;
    color: #ffffff !important;
    box-shadow: none !important;
}

.secondaryDisabled {
    background-color: #ebebeb !important;
    border: none !important;
    color: #adadad !important;
    box-shadow: none !important;
}

.primary {
    background-color: #333333 !important;
    color: white !important;
    border: none !important;
    box-shadow: 0px 15px 15px rgba(107, 107, 107, 0.25) !important;
}

.primary:hover {
    color: white !important;
    background-color: #333333 !important;
    border: none !important;
}

.primary:focus {
    color: white !important;
    background-color: #333333 !important;
    border: none !important;
}

img {
    margin-bottom: 3px;
}

.purple {
    background-color: #e062ff !important;
    box-shadow: 0px 15px 15px rgba(224, 98, 255, 0.25) !important;
    color: white !important;
    border: none !important;
}

.purple:hover {
    background-color: #e062ff !important;
    color: white !important;
}

.purple:focus {
    background-color: #e062ff !important;
    color: white !important;
}

.grey {
    background-color: #e0e0e0 !important;
    border: none !important;
    color: white !important;
}

.grey:hover {
    background-color: #e0e0e0 !important;
    color: white !important;
}

.grey:focus {
    background-color: #e0e0e0 !important;
    color: white !important;
}

.secondary {
    background-color: #ebebeb !important;
    border: none !important;
    color: #333333 !important;
    box-shadow: 0px 15px 15px rgba(107, 107, 107, 0.25) !important;
}

.secondary:hover {
    background-color: #ebebeb !important;
    border: none !important;
    color: #333333 !important;
}

.secondary:focus {
    background-color: #ebebeb !important;
    border: none !important;
    color: #333333 !important;
}
