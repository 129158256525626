.regFormWrapper {
  margin-top: 33px;
  width: 100%;
  padding: 0 42px 0 46px;
  overflow: scroll;
  height: calc(100vh - 100px);
}

.regTitle {
  color: #333333;
}

.regTitle > h1 {
  font-family: 'TT Norms Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  padding: 0;
}

.regTitle > p > a {
  color: #999cad;
  text-decoration: underline;
}

.regTitle p {
  font-family: 'TT Norms Pro';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 6px;
  margin-bottom: 0;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.regFormInputWrapper {
  margin-top: 29px;
  width: 100%;
}

.formItem {
  margin-top: 18px;
}

.label {
  color: #999cad;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 14px;
}

.passwordValidation {
  margin-bottom: 20px;
}

.btn  {
  width: 100%
}


/*@media (max-width: 320px) {*/
/*  .regFormWrapper {*/
/*    width: 100%;*/
/*  }*/

/*  .regTitle {*/
/*    font-size: 6.5625vw !important;*/
/*    line-height: 20px !important;*/
/*  }*/

/*  .terms {*/
/*    width: 100%;*/
/*    font-size: 4.375vw;*/
/*  }*/

/*  .btn {*/
/*    width: 100%;*/
/*    min-width: auto;*/
/*    font-size: 4.375vw !important;*/
/*  }*/
/*}*/
