.container {
  display: inline-flex;
  min-height: 100%;
  width: 100vw;
  background-color: #fff;
}

.mainLeftStretched {
  width: calc(100vw - 375px);
}

.rightpanel {
  right: 0;
  width: 380px;
  border-left: solid 1px #eaeaea;
  position: fixed;
  padding: 5px;
  background-color: #fff;
}

.mainFullStretched {
  width: 100%;
}

.modalContainer {
  position: fixed;
  left: 0;
  right: 0;
  background-color: white;
  top: 0;
  padding: 10px;
  overflow-y: auto;
  height:100vh;
  width:100vw
}

.hide {
  display: none;
  visibility: hidden;
}

.body {
  font-family: "TT Norms Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
