@font-face {
    font-family: 'TT Norms Pro';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro'), url('./fonts/TTNormsPro/TT Norms Pro Regular.otf') format('opentype');
}

@font-face {
    font-family: 'TT Norms Pro Bold Italic';
    font-style: normal;
    font-weight: 700;
    src: local('TT Norms Pro Bold Italic'), url('./fonts/TTNormsPro/TT Norms Pro Bold Italic.otf') format('opentype');
}

@font-face {
    font-family: 'TT Norms Pro Bold';
    font-style: normal;
    font-weight: 700;
    src: local('TT Norms Pro Bold'), url('./fonts/TTNormsPro/TT Norms Pro Bold.otf') format('opentype');
}

@font-face {
    font-family: 'TT Norms Pro Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro Italic'), url('./fonts/TTNormsPro/TT Norms Pro Italic.otf') format('opentype');
}

@font-face {
    font-family: 'TT Norms Pro Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro Light Italic'), url('./fonts/TTNormsPro/TT Norms Pro Light Italic.ttf') format('opentype');
}

@font-face {
    font-family: 'TT Norms Pro Light';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro Light'), url('./fonts/TTNormsPro/TT Norms Pro Light.otf') format('opentype');
}