.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.innerWarpper {
  width: 80%;
  max-width: 1000px;
}

.antCollapse {
  width: 100%;
  background: #ffffff !important;
  border: none !important;
}

.antCollapseItem {
  background: #ffffff;
  min-height: 66px;
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
}

.activeHeaderItem {
  background: #BECAFF;
}

.itemContainer {
  display: flex;
}

.headerContainer {
  display: flex;
  max-height: 62px;
}

.rightHeader {
  padding-left: 17px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}

.createPaymentButton {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.01em;
  height: 66px;
  width: 100%;
  border-radius: 4px;
  background-color: #ebebeb;
  text-align: left;
  margin-bottom: 16px;
  color: #333333;
  border: none;
  cursor: pointer;
}


/***** drop down styles ****/
.drop_down_header {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #333333;
}

.drop_down_date {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
  text-align: left;
  bottom: 20px;
  position: relative;
}

.names_div {
  margin-left: 20px;
}

.names {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #333333;
}

.payment_type {
  color: #adadad;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
}

.bank_account {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #333333;
}

.totals {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.01em;
  color: #333333;
}
.reviewing_status {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.01em;
  color: #333333;
}
.fees {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.01em;
  color: #333333;
}

.fees > span {
  color: #adadad;
}

.lower_row {
  flex-flow: row !important;
  padding-top: 25px;
}

.lower_div {
  margin-top: 12px;
}


.plus {
  font-size: 27px;
  padding: 13px 24px 13px 13px;
}

.noPayment {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.noPayment > p {
  margin-top: 10px;
  color: #ADADAD;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.image_div {
  width: 20vw;
  height: 20vw;
  max-width: 150px;
  max-height: 150px;
}