.phoneInput > input {
  border:1px solid #ebebeb !important;
  border-radius: 4px !important;
  border-width: 1px !important;
  line-height: 39px;
  outline: none;
  height: 58px;
  color:#adadad;
  font-size: 14px !important;
  line-height: 14px;
  width: 100% !important;
}
.phoneInput > input:focus {
  border:1px solid #ADADAD !important;
  box-shadow: none !important;
  color: #333333 !important;
}
.phoneInput > input:hover {
  border-color: #ADADAD !important;
}
.phoneInput.error > input {
    border-color: #F37D80 !important;
}

.phoneInput > input::placeholder{
  font-size: 14px !important;
  font-family: 'TT Norms Pro';
  line-height: 14px;
  color: RGB(180, 180, 180);
}

.phoneInput > div{
    color: #999CAD !important;
    font-family: 'TT Norms Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 14px;
}

.createCard div{
    color: #999CAD !important;
    font-family: 'TT Norms Pro';
    font-style: normal;
    font-weight: normal;
    line-height: 14px;
    font-size: 14px !important;
}

.phoneInput.error > div{
  color: #F37D80 !important;
}

.createCard > input {
  width: 100% !important;
  border: 1px solid #ebebeb !important;
  box-sizing: border-box;
  border-radius: 4px !important;
}

.createCard > input:focus {
  border:1px solid #ADADAD !important;
  box-shadow: none !important;
  color: #333333 !important;
}
.createCard > input:hover {
  border-color: #ADADAD !important;
}
